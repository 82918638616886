<template>

    <StaffList
        :heading="`School Staff Directory`"
        :loading="$store.getters.loading"
        :staffList="list"></StaffList>

</template>

<script>

    import StaffList from '../components/layouts/StaffList';
    import {getSchoolStaff} from '../lib/api';
    import {getPrivateSchoolStaff} from '../lib/private-api';
    export default {
        name: "school-result-page",
        components: {
            StaffList
        },

        data: () => ({
            list: [],
        }),

        methods: {
            mapStaff: function(group) {
                return {
                    groupName: (group["Unit Name"] == null) ? "" : group["Unit Name"],
                    groupType: (group["Unit Type"] == null) ? "" : group["Unit Type"],
                    staffList: group["Staff"]
                };
            },


            /**
            * Handle a successful search response from the API
            */
            successSearch: function(res) {
                this.$store.commit('setLoading', false);

                // Check if API returned any results
                if (res.data.length == 0) {
                    this.$store.commit("errorMsg", "No results were found");
                } else {
                    // store result in vuex
                    this.$store.commit("updateStaff", res);
                    this.list = this.$store.getters.staff.map(this.mapStaff);
                }
            },

            /**
            * Handle a successful search response from the API
            */
            errorSearch: function(err) {
                // display error message
                this.$store.commit('setLoading', false);
                this.$store.commit("errorMsg", `There was an error retrieving results`);
                this.errorLogin(err);
            },
        },

        mounted: function() {
            
            if (this.$store.getters.staff.length == 0) {
                this.$store.commit('setLoading', true);

                var school = this.$route.params["school"];

                if (this.$store.getters.isLoggedIn) {
                    getPrivateSchoolStaff(this.$store.getters.token, school)
                        .then((res) => {
                            this.successSearch(res);
                        })
                        .catch((err) => {
                            this.errorSearch(err);
                        })
                } else {
                    getSchoolStaff(school)
                        .then((res) => {
                            this.successSearch(res);
                        })
                        .catch((err) => {
                            this.errorSearch(err);
                        })
                }
            } else {

                this.$store.commit('setLoading', false);
                // define list into groups
                this.list = this.$store.getters.staff.map(this.mapStaff);
            }
        }
    }
</script>